import React, { Suspense, lazy, useEffect, useContext } from "react";
import { BrowserRouter, Switch, Route, Routes, Navigate, Outlet } from "react-router-dom";
import { Redirect } from 'react-router'
import "antd/dist/reset.css";


import logo from './logo.svg';
import './App.css';

import { AuthProvider, AuthContext } from "./context/AuthContext";

import { MTN_COLOR_DEFAULT,AIRTEL_COLOR_DEFAULT, GLO_COLOR_DEFAULT, REACT_NETWORK_PROVIDER, } from "./constants";


const Login = lazy(() => import("./pages/authentication/Login"));
const Register = lazy(() => import("./pages/authentication/Register"));
const TandC = lazy(() => import("./pages/t&c/TandC"));
const WelcomePage = lazy(() => import("./pages/welcome/WelcomePage"));
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const PlayGame = lazy(() => import("./pages/playgame/PlayGame"));
const Congratulations = lazy(() => import("./pages/completed/Congratulations"));
const TryAgain = lazy(() => import("./pages/tryagain/TryAgain"));
const HighScore = lazy(() => import("./pages/highscore/HighScore"));
const EarnedPrice = lazy(() => import("./pages/price/EarnedPrice"));

const Settings = lazy(() => import("./pages/settings/Settings"));


const LeaderBoard = lazy(() => import("./pages/leaderboard/LeaderBoard"));

const MyGame = lazy(() => import("./pages/game/MyGame"));

// import {
//   BrowserRouter as Router,
//   Suspense,
//   Route,
//   Link,
//   BrowserRouter
// } from "react-router-dom";



const AuthenticatedRoute = ({ children, ...rest }) => {
  const auth = useContext(AuthContext);
  return (
    auth.isAuthenticated() ? <Outlet /> : <Navigate to="/" />
  );
};



function App() {
  const baseUrl = process.env.PUBLIC_URL;


  return (



    <BrowserRouter basename={baseUrl}>
      <Suspense fallback={<CustomLoader />}>
        <Routes>

          <Route exact path='/dashboard' element={<AuthenticatedRoute />}>
            <Route exact path='/dashboard' element={<Dashboard />} />
          </Route>

          <Route path="/" element={<Login />} />

          <Route path="/sign-up" element={<Register />} />

          <Route path="/terms-and-coditions" element={<TandC />} />

          <Route exact path='/welcome' element={<AuthenticatedRoute />}>
            <Route path="/welcome" element={<WelcomePage />} />
          </Route>

          <Route exact path='/play-game' element={<AuthenticatedRoute />}>
            <Route path="/play-game" element={<PlayGame />} />
          </Route>

          <Route exact path='/congratulations' element={<AuthenticatedRoute />}>
            <Route path="/congratulations" element={<Congratulations />} />
          </Route>

          <Route exact path='/tryagain' element={<AuthenticatedRoute />}>
            <Route path="/tryagain" element={<TryAgain />} />
          </Route>

          <Route exact path='/highscore' element={<AuthenticatedRoute />}>
            <Route path="/highscore" element={<HighScore />} />
          </Route>

          <Route exact path='/earned-price' element={<AuthenticatedRoute />}>
            <Route path="/earned-price" element={<EarnedPrice />} />
          </Route>

          <Route exact path='/leaderboard' element={<AuthenticatedRoute />}>
            <Route path="/leaderboard" element={<LeaderBoard />} />
          </Route>

          <Route exact path='/mygame' element={<AuthenticatedRoute />}>
            <Route path="/mygame" element={<MyGame />} />
          </Route>

          <Route exact path='/settings' element={<AuthenticatedRoute />}>
            <Route path="/settings" element={<Settings />} />
          </Route>

          <Route path="*" element={<NoMatch />} />
        </Routes>
      </Suspense>
    </BrowserRouter>


  );
}


const NoMatch = () => {
  return <p>There's nothing here: 404!</p>;
};




export default App;



const CustomLoader = () => {
  let colorStyle;
  switch (REACT_NETWORK_PROVIDER) {
    case 'glo':
      colorStyle = { color: GLO_COLOR_DEFAULT };
      break;
    case 'mtn':
      colorStyle = { color: MTN_COLOR_DEFAULT };
      break;
      case 'airtel':
      colorStyle = { color: AIRTEL_COLOR_DEFAULT };
      break;
    default:
      // Set a default color in case REACT_NETWORK_PROVIDER is not recognized
      colorStyle = { color: "rgb(163, 0, 125)" };
  }

  return (
    <div className="centered" style={{ width: "inherit", height: "inherit" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "inherit",
        }}
      >
        <h3 className="splash_screen">
        <span style={colorStyle}>
        {REACT_NETWORK_PROVIDER === 'mtn' ? 'MTN' : REACT_NETWORK_PROVIDER === 'airtel' ? 'Airtel' : 'GLO'}
          </span> <span style={colorStyle}>F</span>astest   <span style={colorStyle}>F</span>ingers

        </h3>

      </div>
    </div>
  );
};

